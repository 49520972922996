<template>
  <div class="container-fluid header-container pt-3">
    <div class="row no-gutters flex-column-reverse flex-xl-row">
      <div class="col-12 col-xl-5 d-flex align-items-center mt-3 mt-xl-0 ps-0">
        <ul class="tab-menu me-auto nav nav-tabs">
          <li class="nav-item">
            <router-link
              class="nav-link"
              active-class="active"
              data-jp="nav-link"
              to="/projects"
              >{{ $t("PROJECTS") }}</router-link
            >
          </li>
          <li class="nav-item">
            <component
              :is="noProjectSelected ? 'span' : 'router-link'"
              class="nav-link"
              :class="noProjectSelected ? ' disabled text-muted' : ''"
              :aria-disabled="noProjectSelected"
              active-class="active"
              :to="`/projects/${projectGuid}/waves`"
              >{{ $t("WAVE_SELECTION") }}</component
            >
          </li>
          <li class="nav-item">
            <component
              :is="noWaveSelected ? 'span' : 'router-link'"
              class="nav-link"
              active-class="active"
              :to="`/projects/${projectGuid}/waves/${waveGuid}/general-setup`"
              :aria-disabled="noWaveSelected"
              :class="noWaveSelected ? ' disabled text-muted' : ''"
              >{{ $t("GENERAL_SETUP") }}</component
            >
          </li>
          <li class="nav-item">
            <component
              :is="noWaveSelected ? 'span' : 'router-link'"
              class="nav-link"
              :class="noWaveSelected ? ' disabled text-muted' : ''"
              :aria-disabled="noWaveSelected"
              active-class="active"
              :to="`/projects/${projectGuid}/waves/${waveGuid}/dealer-adresses`"
              >{{ $t("UPDATE_DEALER") }}</component
            >
          </li>
          <li class="nav-item">
            <component
              :is="noWaveSelected ? 'span' : 'router-link'"
              class="nav-link"
              active-class="active"
              :to="`/projects/${projectGuid}/waves/${waveGuid}/appeal-management`"
              :aria-disabled="noWaveSelected"
              :class="noWaveSelected ? ' disabled text-muted' : ''"
              >{{ $t("APPEAL_MANAGEMENT") }}</component
            >
          </li>
          <li class="nav-item">
            <component
              :is="noWaveSelected ? 'span' : 'router-link'"
              class="nav-link"
              active-class="active"
              :to="`/projects/${projectGuid}/waves/${waveGuid}/reporting-setup`"
              :class="noWaveSelected ? ' disabled text-muted' : ''"
              :aria-disabled="noWaveSelected"
              >{{ $t("REPORTING_SETUP") }}</component
            >
          </li>
          <li class="nav-item">
            <component
              :is="noWaveSelected ? 'span' : 'router-link'"
              class="nav-link"
              active-class="active"
              :to="`/projects/${projectGuid}/waves/${waveGuid}/configurations`"
              :class="noWaveSelected ? ' disabled text-muted' : ''"
              :aria-disabled="noWaveSelected"
              data-jp="nav-link-configuration"
              >{{ $t("CONFIGURATION") }}</component
            >
          </li>
        </ul>
      </div>
      <div
        class="col-12 col-xl-2 d-flex justify-content-center align-items-center"
      >
        <img class="logo" src="../assets/images/porsche_logo.png" />
      </div>
      <div
        class="col-12 col-xl-5 d-flex justify-content-end align-items-center"
      >
        <button type="button" class="btn btn-secondary ms-3" @click="logout()">
          {{ $t("LOGOUT") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import keyCloak from "../main";

export default {
  name: "Header",
  methods: {
    logout() {
      keyCloak.logout();
    },
  },
  setup() {
    return {};
  },
  computed: {
    noProjectSelected() {
      return !this.$store.getters["projects/getActiveProject"];
    },
    noWaveSelected() {
      return !this.$store.getters["waves/getActiveWave"];
    },
    projectGuid() {
      return this.$store.getters["projects/getActiveProject"]
        ? this.$store.getters["projects/getActiveProject"].guid
        : "";
    },
    waveGuid() {
      return this.$store.getters["waves/getActiveWave"]
        ? this.$store.getters["waves/getActiveWave"].guid
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  min-width: auto;
}

.disabled {
  cursor: not-allowed;
  pointer-events: all;
}

.btn-outline-secondary {
  min-width: auto;
  width: 70px;
}

.header-container {
  padding-left: var(--gloabl-padding-right) !important;
  padding-right: var(--gloabl-padding-right) !important;
}

.logo {
  max-height: 80px;
}

.nav-tabs {
  border-bottom: none;

  .nav-link {
    border-bottom: 2px solid transparent;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    color: black;
    padding: 0 0.3rem 5px;

    &.active,
    &:hover {
      color: $primary;
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
      border-bottom: 2px solid $primary;
    }
  }
}

.tab-menu li:not(:first-of-type) {
  margin-left: 10px;
}
</style>
